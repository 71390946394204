import Link from "next/link";
import clsx from "clsx";

import { Alert } from "~/components";

interface Props {
  title: string;
  description: string;
  linkText: string;
  compressed?: boolean;
  className?: string;
  onClick?: () => void;
}

export default function UpsellAlert({
  title,
  description,
  linkText,
  compressed,
  className,
  onClick,
}: Props) {
  return (
    <div className={clsx(className, "w-full")}>
      <Alert appearance="primary">
        <div
          className={clsx(
            "flex",
            compressed ? "flex-col" : "flex-row justify-between",
          )}
        >
          <div className="flex flex-col gap-1 text-base">
            <div className="font-bold">{title}</div>
            <div>{description}</div>
          </div>
          <div className="flex min-w-max items-end pt-2">
            <Link
              href="/account/plans"
              className="text-base underline"
              onClick={onClick}
            >
              {linkText}
            </Link>
          </div>
        </div>
      </Alert>
    </div>
  );
}
