import { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import { useTranslations } from "next-intl";

import FullscreenModal from "~/components/FullscreenModal";
import UpsellAlert from "~/components/UpsellAlert";
import { useStoreInfo } from "~/app/[locale]/(components)/StoreInfoProvider";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import useHotkeys from "~/hooks/useHotkeys";
import { usePathname, useRouter } from "~/navigation";
import TableRow from "./TableRow";

type Props = {
  open?: boolean;
  onAfterModalLeave?: () => void;
};

export default function ShortcutListModalWidget({
  open,
  onAfterModalLeave,
}: Props) {
  const t = useTranslations("widgets.shortcut-list-modal");
  const { features } = useStoreInfo() || {};
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [openFromShortcut, setOpenFromShortcut] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (open || openFromShortcut) {
      params.set("step", "shortcutList");
    }

    router.push(`${pathname}?${params.toString()}`);
  }, [open, openFromShortcut, pathname, router, searchParams]);

  useHotkeys("?", () => setOpenFromShortcut(true), {
    useKey: true,
  });

  const handleAfterModalLeave = () => {
    router.back();
    setOpenFromShortcut(false);
    onAfterModalLeave?.();
  };

  const handleUpsellAlertClick = () => {
    trackAmplitudeEvent("pdv_all_see_plans_click", {
      from: "friction_shortcuts",
    });
  };

  return (
    <FullscreenModal
      open={open || openFromShortcut}
      onAfterModalLeave={handleAfterModalLeave}
      title={t("title")}
      contentWidthClass="w-[800px]"
    >
      <div className="flex min-h-0 w-full flex-1 flex-col items-center overflow-y-auto">
        {!features?.keyboardShortcuts && (
          <UpsellAlert
            title={t("upgradePlanAlert.title")}
            description={t("upgradePlanAlert.description")}
            linkText={t("upgradePlanAlert.linkText")}
            onClick={handleUpsellAlertClick}
            compressed
            className="!w-[800px] pb-4"
          />
        )}

        <div className="m-0.5 flex min-h-0 w-[800px] gap-6">
          <table className="w-[388px] rounded-lg p-4 shadow-2">
            <thead>
              <tr className="flex h-12 items-center border-b p-4">
                <th className="text-base font-semibold">
                  {t("carrinho.title")}
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRow
                description={t("carrinho.items.customer")}
                keys={["A", "C"]}
              />
              <TableRow
                description={t("carrinho.items.discount")}
                keys={["A", "D"]}
              />
              <TableRow
                description={t("carrinho.items.shipping")}
                keys={["A", "E"]}
              />
              <TableRow
                description={t("carrinho.items.note")}
                keys={["A", "W"]}
              />
              <TableRow
                description={t("carrinho.items.product")}
                keys={["A", "X"]}
              />
              <TableRow description={t("carrinho.items.search")} keys={["B"]} />
              <TableRow
                description={t("carrinho.items.payment")}
                keys={["P"]}
              />
              <TableRow
                description={t("carrinho.items.clear")}
                keys={["Delete / Backspace"]}
              />
              <TableRow description={t("carrinho.items.save")} keys={["G"]} />
              <TableRow description={t("carrinho.items.share")} keys={["C"]} />
            </tbody>
          </table>

          <div className="flex flex-col gap-6">
            <table className="h-min w-[388px] rounded-lg p-4 shadow-2">
              <thead>
                <tr className="flex h-12 items-center border-b p-4">
                  <th className="text-base font-semibold">
                    {t("payment.title")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <TableRow
                  description={t("payment.items.method")}
                  keys={["1", "8"]}
                  withRangeSeparator
                />
                <TableRow
                  description={t("payment.items.otherMethod")}
                  keys={["9"]}
                />
                <TableRow
                  description={t("payment.items.pending")}
                  keys={["0"]}
                />
              </tbody>
            </table>

            <table className="h-min w-[388px] rounded-lg p-4 shadow-2">
              <thead>
                <tr className="flex h-12 items-center border-b p-4">
                  <th className="text-base font-semibold">
                    {t("general.title")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <TableRow
                  description={t("general.items.shortcutList")}
                  keys={["?"]}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
}
