import { type PropsWithChildren } from "react";
import { Dialog } from "@headlessui/react";

export default function FullscreenModalDescription({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <Dialog.Description className="text-lg font-normal leading-6 text-neutral-text-low">
      {children}
    </Dialog.Description>
  );
}
