import { type PropsWithChildren } from "react";

export default function FullscreenModalFooter({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex justify-center border-t border-t-neutral-surface-disabled bg-white py-6">
      <div className="flex w-[600px] gap-2">{children}</div>
    </div>
  );
}
