"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "next/navigation";
import { useTranslations } from "next-intl";

import ConfirmModal from "~/components/ConfirmModal";
import { useModalContext } from "~/app/[locale]/(components)/ModalProvider";
import { useStoreInfo } from "~/app/[locale]/(components)/StoreInfoProvider";
import { appTRPC } from "~/app/[locale]/(components)/TRPCProvider";
import { useToast } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { useCheckoutStore } from "~/hooks/store";
import useSelectedLocation from "~/hooks/useSelectedLocation";
import { usePathname, useRouter } from "~/navigation";

const MIN_BARCODE_LENGTH = 7;

export default function BarcodeScannerWidget() {
  const t = useTranslations("widgets.barcode-scanner");
  const [scannedBarcode, setScannedBarcode] = useState("");
  const {
    modalOpen: upsellModalOpen,
    openModal: openUpsellModal,
    closeModal: closeUpsellModal,
  } = useModalContext("upsell-barcode-scanner");
  const scannerInputRef = useRef("");
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { features, hasMulticd } = useStoreInfo() || {};
  const { addCartItem, cartItems, setHasScannedProducts } = useCheckoutStore();
  const { addToast, closeToast } = useToast();
  const { selectedFulfillmentLocation } = useSelectedLocation();

  const shouldFilterByLocation =
    hasMulticd && !!selectedFulfillmentLocation?.id;

  const { data, isLoading, isError } = appTRPC.products.getProducts.useQuery(
    {
      q: scannedBarcode,
      locationId: shouldFilterByLocation
        ? selectedFulfillmentLocation.id
        : undefined,
    },
    {
      enabled: !!scannedBarcode,
    },
  );

  const commonEventProps = useMemo(
    () => ({
      code: scannedBarcode,
      from: "checkout", // TODO: Handle "search" once scanning in search flow is supported
      device: "external", // TODO: Handle "internal" once scanning through camera is supported
    }),
    [scannedBarcode],
  );

  const shouldPreventScan = searchParams.has("step") || isLoading;

  const handleScan = useCallback(
    (barcode: string) => {
      if (shouldPreventScan) {
        return;
      }

      if (!features?.scanProducts) {
        openUpsellModal();
        trackAmplitudeEvent("pdv_checkout_scan", {
          ...commonEventProps,
          result: "blocked",
        });
        trackAmplitudeEvent("pdv_upsell_prompt_view", {
          prompt_type: "after_scan_external",
        });
        return;
      }
      setScannedBarcode(barcode);
    },
    [
      shouldPreventScan,
      features?.scanProducts,
      openUpsellModal,
      commonEventProps,
    ],
  );

  const handleUpsellModalDismiss = () => {
    closeUpsellModal();
  };

  const handleUpsellModalConfirm = () => {
    closeUpsellModal();

    trackAmplitudeEvent("pdv_all_see_plans_click", {
      from: "friction_barcode_scanner",
    });

    router.push("/account/plans");
  };

  // Listen to barcode scanner input
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === "Enter" &&
        scannerInputRef.current.length >= MIN_BARCODE_LENGTH
      ) {
        e.preventDefault();
        handleScan(scannerInputRef.current);
        return;
      }

      scannerInputRef.current += e.key;

      timer = setTimeout(() => {
        scannerInputRef.current = "";
      }, 300);
    };

    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      clearTimeout(timer);
    };
  }, [handleScan]);

  // Handle product search results
  useEffect(() => {
    if (isLoading) {
      addToast({
        id: "scan-loading-toast",
        text: t("toast.loading"),
        type: "progress",
        duration: 16000,
      });
    }

    if (isError) {
      closeToast("scan-loading-toast");
      addToast({
        id: `scan-error-toast-${Date.now()}`,
        text: t("toast.error"),
        type: "danger",
        duration: 4000,
      });

      trackAmplitudeEvent("pdv_checkout_scan", {
        ...commonEventProps,
        result: "failed",
      });

      setScannedBarcode("");
    }

    if (data) {
      closeToast("scan-loading-toast");

      const { items: products = [] } = data;

      const firstProductMatchedVariants =
        products[0]?.variants.filter(
          ({ barcode }) => barcode === scannedBarcode,
        ) || [];

      if (products.length === 0) {
        addToast({
          id: `scan-not-found-toast-${Date.now()}`,
          text: t("toast.notFound"),
          type: "danger",
          duration: 4000,
        });

        trackAmplitudeEvent("pdv_checkout_scan", {
          ...commonEventProps,
          result: "not_found",
        });
      } else if (
        products.length === 1 &&
        firstProductMatchedVariants.length === 1
      ) {
        // Using "!" to assert that the first element exists, as TypeScript can't infer this from the length check
        addCartItem(products[0]!, firstProductMatchedVariants[0]!);
        setHasScannedProducts(true);

        const hasItemInCart = cartItems.some(
          ({ product, variant }) =>
            product.id === products[0]?.id &&
            variant.id === firstProductMatchedVariants[0]?.id,
        );

        trackAmplitudeEvent("pdv_checkout_scan", {
          ...commonEventProps,
          result: hasItemInCart ? "increase" : "added",
        });

        addToast({
          id: `scan-success-toast-${Date.now()}`,
          text: t("toast.success"),
          type: "success",
          duration: 4000,
        });
      } else {
        const params = new URLSearchParams(searchParams);
        params.set("step", "products");
        params.set("q", scannedBarcode);
        router.push(`${pathname}?${params.toString()}`);
        setHasScannedProducts(true);

        trackAmplitudeEvent("pdv_checkout_scan", {
          ...commonEventProps,
          result: "multiple",
        });
      }

      setScannedBarcode("");
    }
  }, [
    addCartItem,
    addToast,
    cartItems,
    closeToast,
    commonEventProps,
    data,
    isError,
    isLoading,
    pathname,
    router,
    scannedBarcode,
    searchParams,
    setHasScannedProducts,
    t,
  ]);

  return (
    <>
      {isLoading && <div className="fixed inset-0" />}
      <ConfirmModal
        open={upsellModalOpen}
        title={t("upgradePlanModal.title")}
        description={t("upgradePlanModal.description")}
        dismissButtonText={t("upgradePlanModal.dismissButton")}
        confirmButtonText={t("upgradePlanModal.confirmButton")}
        onDismiss={handleUpsellModalDismiss}
        onConfirm={handleUpsellModalConfirm}
      />
    </>
  );
}
