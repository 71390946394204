"use client";

import { useCallback, type ReactNode } from "react";
import { CloseIcon } from "@nimbus-ds/icons";
import clsx from "clsx";
import { differenceInCalendarDays } from "date-fns";
import format from "date-fns/format";
import { size } from "lodash";
import ms from "ms";
import { useTranslations } from "next-intl";
import { createPortal } from "react-dom";
import useLocalStorageState from "use-local-storage-state";

import { SUBSCRIPTION_DAYS_UNTIL_EXPIRATION_WARNING } from "@offline/constants/subscription";

import { appTRPC } from "~/app/[locale]/(components)/TRPCProvider";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import openNewTab from "~/helpers/openNewTab";
import { useCanPerformSecuredApiCalls } from "~/hooks";
import { useRouter } from "~/navigation";

export default function SubscriptionBannerWidget() {
  // Prevents "ReferenceError: document is not defined" error
  if (typeof window === "undefined") {
    return null;
  }

  const subscriptionBannerEl = document.getElementById("subscription-banner");
  const portal = subscriptionBannerEl
    ? createPortal(<SubscriptionBanner />, subscriptionBannerEl)
    : null;

  return portal;
}

function SubscriptionBanner() {
  const t = useTranslations("widgets.subscription-banner");
  const router = useRouter();
  const canPerformSecuredApiCalls = useCanPerformSecuredApiCalls();

  const { data: subscriptionSummary } =
    appTRPC.planSubscriptions.getSummary.useQuery(undefined, {
      enabled: canPerformSecuredApiCalls,
      gcTime: Infinity,
      staleTime: ms("5 minutes"),
    });

  const { mutateAsync: generatePaymentLink } =
    appTRPC.planSubscriptions.payNextPeriod.useMutation();

  const { mutateAsync: retryCurrentPeriodPayment } =
    appTRPC.planSubscriptions.retryCurrentPeriodPayment.useMutation();

  const { subscription, latestChargeStatus } = subscriptionSummary ?? {};
  const { planPrices } = subscription?.plan ?? {};

  const hasPendingPayment =
    !!latestChargeStatus && latestChargeStatus !== "PAID";

  const handlePaySubscription = useCallback(async () => {
    if (size(planPrices) > 1 && !hasPendingPayment) {
      router.push("/account/payment-period");
      return;
    }

    const planPriceId = planPrices?.[0]?.id;

    if (!planPriceId) {
      return;
    }

    let paymentLink;

    if (hasPendingPayment) {
      ({ paymentLink } = await retryCurrentPeriodPayment());
    } else {
      ({ paymentLink } = await generatePaymentLink({ planPriceId }));
    }

    openNewTab(paymentLink, t("popUpBlockedMessage"));
  }, [
    planPrices,
    hasPendingPayment,
    t,
    router,
    retryCurrentPeriodPayment,
    generatePaymentLink,
  ]);

  const expirationDateSuffix = subscription?.endDate
    ? new Date(subscription.endDate).getTime()
    : "default";

  const [isExpirationMessageClosed, setIsExpirationMessageClosed] =
    useLocalStorageState(
      `widgets.subscription-banner.expiration.closed.${expirationDateSuffix}`,
      {
        defaultValue: false,
      },
    );

  const [isPaymentSuccessClosed, setIsPaymentSuccessClosed] =
    useLocalStorageState(
      `widgets.subscription-banner.payment.success.closed.${expirationDateSuffix}`,
      {
        defaultValue: false,
      },
    );

  if (!canPerformSecuredApiCalls || !subscriptionSummary || !subscription) {
    return null;
  }

  if (latestChargeStatus === "CANCELLED" || latestChargeStatus === "FAILED") {
    return (
      <MessageBox className="bg-danger-surface">
        {t("paymentFailed.message")}&nbsp;
        <button
          onClick={handlePaySubscription}
          className="text-primary-text-high underline"
        >
          {t("paymentFailed.action")}
        </button>
      </MessageBox>
    );
  }

  if (latestChargeStatus === "PENDING" || latestChargeStatus === "IN_PROCESS") {
    return (
      <MessageBox className="bg-primary-surface">
        {t("paymentInProcess.message")}&nbsp;
      </MessageBox>
    );
  }

  const daysToExpiration = subscription.endDate
    ? differenceInCalendarDays(subscription.endDate, new Date())
    : null;

  if (
    subscriptionSummary.status === "ACTIVE" &&
    subscription.plan.category === "advanced-free-trial"
  ) {
    return (
      <MessageBox className="bg-primary-surface">
        {t("activeFreeTrial.message", { days: daysToExpiration })}
        <button
          className="ml-2 text-primary-text-high underline"
          onClick={() => {
            trackAmplitudeEvent("pdv_all_see_plans_click", {
              from: "trial_banner",
            });
            router.push("/account/plans");
          }}
        >
          {t("activeFreeTrial.action")}
        </button>
      </MessageBox>
    );
  }

  if (
    daysToExpiration !== null &&
    daysToExpiration < SUBSCRIPTION_DAYS_UNTIL_EXPIRATION_WARNING &&
    !isExpirationMessageClosed
  ) {
    return (
      <MessageBox
        className="bg-warning-surface"
        onClose={() => setIsExpirationMessageClosed(true)}
      >
        {t("aboutToExpire.message", { days: Math.max(daysToExpiration, 0) })}
        &nbsp;
        <button
          onClick={handlePaySubscription}
          className="text-primary-text-high underline"
        >
          {t("aboutToExpire.action")}
        </button>
      </MessageBox>
    );
  }

  if (
    latestChargeStatus === "PAID" &&
    subscription.endDate &&
    !isPaymentSuccessClosed
  ) {
    const untilDate = format(new Date(subscription.endDate), "dd/MM/yyyy");

    return (
      <MessageBox
        className="bg-success-surface"
        onClose={() => setIsPaymentSuccessClosed(true)}
      >
        {t("paymentSuccess.message", { expirationDate: untilDate })}
        &nbsp;
      </MessageBox>
    );
  }

  return null;
}

function MessageBox({
  children,
  className,
  onClose,
}: {
  children: ReactNode;
  className?: string;
  onClose?: () => void;
}) {
  return (
    <div
      className={clsx(
        "flex w-full items-center justify-center py-4 pl-6 pr-14 text-center text-lg",
        className,
      )}
    >
      {children}
      {onClose && (
        <button className="absolute right-6" onClick={onClose}>
          <CloseIcon size="medium" />
        </button>
      )}
    </div>
  );
}
