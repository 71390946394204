"use client";

import {
  ShoppingCartIcon,
  StatsIcon,
  TiendanubeIcon,
  WalletIcon,
} from "@nimbus-ds/icons";
import clsx from "clsx";
import { useTranslations } from "next-intl";

import { useModalContext } from "~/app/[locale]/(components)/ModalProvider";
import { useStoreInfo } from "~/app/[locale]/(components)/StoreInfoProvider";
import { IconBox, Tooltip } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { Link, usePathname } from "~/navigation";

type AppItem = "app" | "cashRegister" | "statistics";

const RoutesByAppItem: Record<AppItem, string> = {
  app: "/app",
  cashRegister: "/registers",
  statistics: "/stats",
};

export default function SidebarWidget() {
  const t = useTranslations();
  const pathname = usePathname();

  const storeInfo = useStoreInfo();
  const { features, userConfig } = storeInfo || {};

  const showStatistics = userConfig?.hasStatisticsAccess;

  const { openModal: openUpsellCashRegisterModal } = useModalContext(
    "upsell-cash-register",
  );

  const handleCashRegisterClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!features?.cashRegister) {
      e.preventDefault();

      trackAmplitudeEvent("pdv_upsell_prompt_view", {
        prompt_type: "friction_cash_register",
      });

      openUpsellCashRegisterModal();
    } else {
      trackAmplitudeEvent("pdv_navbar_register_click");
    }
  };

  return (
    <div className="flex flex-col items-center gap-10 border-r border-neutral-surface-highlight bg-neutral-background p-4">
      <TiendanubeIcon size={40} />

      <div className=" flex flex-col gap-4">
        <Link href={RoutesByAppItem.app}>
          <Tooltip content={t("widgets.sidebar.cart")} position="right">
            <IconBox
              icon={<ShoppingCartIcon size={24} />}
              className={clsx(
                !pathname.startsWith(RoutesByAppItem.app) && "bg-transparent",
              )}
            />
          </Tooltip>
        </Link>

        <Link
          href={RoutesByAppItem.cashRegister}
          onClick={handleCashRegisterClick}
        >
          <Tooltip content={t("widgets.sidebar.cashRegister")} position="right">
            <IconBox
              icon={<WalletIcon size={24} />}
              className={clsx(
                !pathname.startsWith(RoutesByAppItem.cashRegister) &&
                  "bg-transparent",
              )}
            />
          </Tooltip>
        </Link>

        {showStatistics && (
          <Link
            href={RoutesByAppItem.statistics}
            onClick={() => trackAmplitudeEvent("pdv_navbar_stats_click")}
          >
            <Tooltip content={t("widgets.sidebar.statistics")} position="right">
              <IconBox
                icon={<StatsIcon size={24} />}
                className={clsx(
                  !pathname.startsWith(RoutesByAppItem.statistics) &&
                    "bg-transparent",
                )}
              />
            </Tooltip>
          </Link>
        )}
      </div>
    </div>
  );
}
