import { type PropsWithChildren } from "react";
import { Dialog } from "@headlessui/react";

export default function FullscreenModalTitle({
  children,
}: Readonly<PropsWithChildren>) {
  return (
    <Dialog.Title className="text-[28px] font-semibold leading-8 text-primary-text-high">
      {children}
    </Dialog.Title>
  );
}
