"use client";

import { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { CloseIcon } from "@nimbus-ds/icons";
import { useTranslations } from "next-intl";
import useLocalStorageState from "use-local-storage-state";

import { appTRPC } from "~/app/[locale]/(components)/TRPCProvider";
import { Button } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { useRouter } from "~/navigation";

const ORDER_COUNT_TRIGGER = 1;

export default function PlanUpsellWidget() {
  const t = useTranslations("widgets.plan_upsell");
  const router = useRouter();
  const [hasPlanUpsellBeenSeen, setHasPlanUpsellBeenSeen] =
    useLocalStorageState("widgets.plan-upsell.seen");

  const { data: subscriptionSummary } =
    appTRPC.planSubscriptions.getSummary.useQuery();

  const isBasicPlan =
    subscriptionSummary?.subscription?.plan.category === "basic";

  const { data: posOrdersCount, isPending } =
    appTRPC.orders.getPosOrdersCount.useQuery(undefined, {
      enabled: isBasicPlan && !hasPlanUpsellBeenSeen,
    });

  const open =
    isBasicPlan &&
    !hasPlanUpsellBeenSeen &&
    !isPending &&
    posOrdersCount === ORDER_COUNT_TRIGGER;

  useEffect(() => {
    if (posOrdersCount && posOrdersCount > ORDER_COUNT_TRIGGER) {
      setHasPlanUpsellBeenSeen(true);
    }
  }, [posOrdersCount, setHasPlanUpsellBeenSeen]);

  useEffect(() => {
    if (open) {
      trackAmplitudeEvent("pdv_upsell_prompt_view", {
        prompt_type: "after_first_sale",
      });
    }
  }, [open]);

  const handleModalClose = () => {
    setHasPlanUpsellBeenSeen(true);
  };

  const handlePlansClick = () => {
    router.push("/account/plans");

    trackAmplitudeEvent("pdv_all_see_plans_click", {
      from: "upsell_first_sale",
    });

    handleModalClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      className="hidden min-res:block"
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-[600px] max-w-2xl rounded-lg bg-white p-6">
          <div className="flex justify-between">
            <Dialog.Title className="lh-[32px] pb-6 text-[24px] font-medium text-primary-text-high">
              {t("title")}
            </Dialog.Title>
            <CloseIcon
              size="medium"
              className="cursor-pointer"
              onClick={handleModalClose}
            />
          </div>

          <div className="relative flex flex-1 flex-col gap-4">
            <p className="text-lg font-normal not-italic leading-6 text-neutral-text-disabled">
              {t("body")}
            </p>
          </div>
          <div className="mt-6 flex justify-center gap-4">
            <Button variant="primary" onClick={handlePlansClick}>
              {t("submitButton")}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
