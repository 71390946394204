import { Dialog } from "@headlessui/react";

import { Button } from "~/components";

type Props = {
  open: boolean;
  title: string;
  description: string;
  confirmButtonText: string;
  dismissButtonText: string;
  onDismiss: () => void;
  onConfirm: () => void;
};

export default function ConfirmModal({
  open,
  title,
  description,
  confirmButtonText,
  dismissButtonText,
  onDismiss,
  onConfirm,
}: Readonly<Props>) {
  return (
    <Dialog open={open} onClose={onDismiss} className="hidden min-res:block">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="mx-auto min-w-fit rounded-lg bg-white p-6">
          <Dialog.Title className="text-xl font-bold">{title}</Dialog.Title>

          <Dialog.Description className="max-w-[632px] py-4 text-lg text-neutral-text-disabled">
            {description}
          </Dialog.Description>

          <div className="flex gap-4">
            <Button variant="neutral" onClick={onDismiss} className="w-full">
              {dismissButtonText}
            </Button>

            <Button variant="primary" className="w-full" onClick={onConfirm}>
              {confirmButtonText}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
