"use client";

import { map } from "lodash";

import logger from "@offline/logger";

import { appTRPC } from "~/app/[locale]/(components)/TRPCProvider";
import { useCheckoutStore, useIsCheckoutValid } from "~/hooks";
import UnpublishedItemsInCartModal from "./UnpublishedItemsInCartModal";

type Props = {
  open: boolean;
  onDismiss: () => void;
  onItemsPublish: () => void;
};

function UnpublishedItemsInCartModalWidget({
  open,
  onDismiss,
  onItemsPublish,
}: Readonly<Props>) {
  const trpcUtils = appTRPC.useUtils();
  const { publishProductsInCart } = useCheckoutStore();
  const {
    mutateAsync: publishProducts,
    isPending: isPublishingProducts,
    error: publishProductsError,
  } = appTRPC.products.publishProducts.useMutation();

  const { unpublishedItems } = useIsCheckoutValid();

  const handleConfirm = async () => {
    const ids = map(unpublishedItems, (item) => item.product.id);

    try {
      await publishProducts({ ids });
      publishProductsInCart(ids);
      await trpcUtils.products.getProducts.invalidate();
      onItemsPublish();
    } catch (err) {
      logger.error("Failed to publish products", err);
    }
  };

  return (
    <UnpublishedItemsInCartModal
      open={open}
      items={unpublishedItems}
      onDismiss={onDismiss}
      onConfirm={handleConfirm}
      error={!!publishProductsError}
      loading={isPublishingProducts}
    />
  );
}

export default UnpublishedItemsInCartModalWidget;
