import { ExclamationCircleIcon, RedoIcon } from "@nimbus-ds/icons";
import { useTranslations } from "next-intl";

type Props = {
  onRetryClick: () => void;
};

function ErrorMessage({ onRetryClick }: Props) {
  const t = useTranslations("widgets.select-location-modal.error");

  return (
    <div className="flex w-full flex-1 flex-col items-center gap-2 pt-4 text-lg">
      <ExclamationCircleIcon size="large" />
      <p className="text-lg font-bold">{t("title")}</p>
      <button
        className="flex items-center gap-2 text-primary-interactive underline hover:text-primary-interactive-hover"
        title={"Action"}
        onClick={onRetryClick}
      >
        <RedoIcon />
        {t("action")}
      </button>
    </div>
  );
}

export default ErrorMessage;
