import { type ProductVariant } from "~/types";

export default function getLocationStock(
  variant: Partial<ProductVariant> & { stock: number },
  selectedLocationId: string | undefined,
) {
  const locationStock = variant?.inventory_levels?.find(
    ({ location_id }) => location_id === selectedLocationId,
  )?.stock;

  return locationStock !== undefined ? locationStock : variant.stock;
}
