"use client";

import { type ReactNode } from "react";
import { useTranslations } from "next-intl";

import { FullscreenErrorMessage } from "~/components";
import { usePathname } from "~/navigation";

type Props = {
  children: ReactNode;
};

export default function ScreenResolutionLimiter({ children }: Props) {
  const t = useTranslations("widgets.screen-resolution-limiter");
  const pathname = usePathname();

  const isNuvemAppPage = pathname === "/nuvem-app-page";

  if (isNuvemAppPage) {
    return <>{children}</>;
  }

  return (
    <>
      <div className="hidden min-res:block">{children}</div>
      <div className="min-res:hidden">
        <FullscreenErrorMessage
          title={t("title")}
          description={t("description")}
        />
      </div>
    </>
  );
}
