import React, {
  Fragment,
  useEffect,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CloseIcon } from "@nimbus-ds/icons";
import clsx from "clsx";

import FullscreenModalDescription from "./FullscreenModalDescription";
import FullscreenModalTitle from "./FullscreenModalTitle";

interface Props extends PropsWithChildren {
  open: boolean;
  title?: string;
  description?: string;
  titleRightContent?: ReactNode;
  contentWidthClass?: string;
  centeredHeader?: boolean;
  closable?: boolean;
  onAfterModalLeave?: () => void;
  onClose?: () => void;
}

const CONTENT_WIDTH_CLASS = "w-[600px]";

export default function FullscreenModal({
  open,
  title,
  description,
  children,
  titleRightContent = null,
  contentWidthClass = CONTENT_WIDTH_CLASS,
  centeredHeader = false,
  closable = true,
  onClose,
  onAfterModalLeave,
}: Readonly<Props>) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleModalDismiss = () => {
    if (!closable) {
      return;
    }

    setShow(false);
    onClose?.();
  };

  return (
    <Transition show={show} as={Fragment} afterLeave={onAfterModalLeave}>
      <Dialog onClose={handleModalDismiss} className="hidden min-res:block">
        <Transition.Child
          as={Fragment}
          enter="transition ease duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-50"
          leave="transition ease duration-300 transform"
          leaveFrom="opacity-50"
          leaveTo="opacity-0"
        >
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-black" />
        </Transition.Child>
        <div className="fixed inset-0 w-screen px-6 pt-3">
          <Transition.Child
            as={Fragment}
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0 translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-full"
          >
            <Dialog.Panel className="relative flex h-full w-full flex-col rounded-lg bg-white">
              <div className="flex min-h-0 w-full flex-col px-6 pt-10">
                {closable && (
                  <button
                    onClick={handleModalDismiss}
                    className="absolute right-4 top-4"
                  >
                    <CloseIcon size="medium" className="cursor-pointer" />
                  </button>
                )}
                <div className="flex min-h-0 w-full flex-1 flex-col items-center">
                  <div
                    className={clsx(
                      "relative flex flex-col gap-1 pb-3",
                      contentWidthClass,
                    )}
                  >
                    <div
                      className={clsx(
                        "flex items-center",
                        centeredHeader ? "justify-center" : "justify-between",
                      )}
                    >
                      <div id="fullscreenmodal-title">
                        {!!title && (
                          <FullscreenModalTitle>{title}</FullscreenModalTitle>
                        )}
                      </div>
                      {titleRightContent}
                    </div>
                    <div id="fullscreenmodal-description">
                      {!!description && (
                        <FullscreenModalDescription>
                          {description}
                        </FullscreenModalDescription>
                      )}
                    </div>
                  </div>

                  <div className="flex min-h-0 w-full flex-1 flex-col items-center pt-3">
                    {children}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
